import { fields } from "./fields";
import { searchMenuItems } from "@monorepo/inventory/src/views/EadView/constants/searchMenuItems";

export const filtersElements = [
  {
    value: fields.FILTER_KEYWORD,
    placeholder: "Поисковый запрос",
    tooltip: "Ручной ввод, поиск по маске",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getSearchAutocomplete",
    className: "filters__full-block",
    appendInnerIcon: "mdi-cog",
    iconTooltip: "Найденные фрагменты будут подсвечены в результатах поиска соответствующим найстройке поиска цветом",
    menuItems: searchMenuItems,
  },
  {
    value: fields.INVENTORY_NUMBER,
    title: "№ описи дел",
    tooltip: "Ручной ввод, поиск по маске",
    type: "autocomplete",
    showSelectedKey: "number",
    autocompleteActionName: "getInvAutocompleteByNumber",
    width: 285,
  },
  {
    value: fields.FILTER_OIK,
    title: "ОИК",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "code",
    autocompleteActionName: "getOikAutocompleteItems",
    width: 285,
  },
  {
    value: fields.INVENTORY_ADD_DATE,
    title: "Дата поступления в ЦХЭД",
    dateValues: [fields.FILTER_INVENTORY_ADD_DATE_FROM, fields.FILTER_INVENTORY_ADD_DATE_TO],
    type: "datePeriod",
    width: 244,
  },
  {
    value: fields.FILTER_FUND,
    title: "Фонд",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "select",
    selectItemsField: "fundNames",
    isShowDeletedIcon: true,
    width: 285,
  },
  {
    value: fields.FILTER_YEAR,
    title: "Год",
    tooltip: "Форма с возможность выбора года",
    type: "year",
    width: 120,
  },
  {
    value: fields.FILTER_STATUS,
    title: "Статус",
    tooltip: "Выбор из следующих вариантов: Утверждена, Удалена",
    type: "select",
    selectItemsField: "statuses",
    width: 285,
  },
  {
    value: fields.INVENTORY_TITLE,
    title: "Заголовок",
    tooltip: "Ручной ввод, поиск по маске",
    type: "text",
    width: 285,
  },
  {
    value: fields.FILTER_CATEGORY,
    title: "Вид",
    tooltip: "Выбор из следующих вариантов: Больше или равно 10 лет, Постоянно, По личному составу",
    type: "select",
    selectItemsField: "categories",
    width: 285,
  },
  {
    value: fields.FILTER_KIND,
    title: "Тип",
    tooltip: "Выбор из следующих вариантов: Передача документов, Изменение сроков хранения документов",
    type: "select",
    selectItemsField: "kinds",
    width: 285,
  },
  {
    value: fields.FILTER_TYPE,
    title: "Тип описи",
    type: "select",
    selectItemsField: "types",
    width: 285,
  },
];
