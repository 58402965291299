











































































































































































































import { defineComponent, ref } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import ToggleDocumentView from "@monorepo/uikit/src/components/common/ToggleDocumentView.vue";
import { mapActions, mapGetters } from "vuex";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { REFRESH_INVENTORY_ACT_TABLE } from "@monorepo/utils/src/eventBus/events/refreshInventoryActTable";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { convertApiItemToUi } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/convertApiItemToUi";
import {
  modalElements,
  techElements,
  mrpElements,
  historicalNoteElements,
} from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/modalElements";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/viewTitle";
import { getFullPath } from "@monorepo/utils/src/api/utils";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { IApiERCProtocolsElement, IERCProtocolsElement } from "@monorepo/inventory/src/views/ERCProtocolsView/types/ERCProtocolsElement";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";
import { v4 as uuid } from "uuid";
import { IModalElementType } from "@monorepo/utils/src/types/modalElementType";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import ExportBtn from "@monorepo/uikit/src/components/tableViews/ExportBtn.vue";
import FormInputElement from "@monorepo/uikit/src/components/tableCardModal/FormInputElement.vue";
import { Route } from "vue-router";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import ActsTable from "./ActsTable.vue";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useCheckChangesModal from "@monorepo/utils/src/composables/useCheckChangesModal";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import useFormLinkByAuthoritiesModal from "@monorepo/utils/src/composables/useFormLinkByAuthoritiesModal";
import { convertSaveCardObject } from "@monorepo/inventory/src/views/ERCProtocolsView/utils/convertSaveCardObject";
import TestImgPreview from "@monorepo/inventory/src/components/TestImgPreview.vue";
import TxtViewer from "@monorepo/inventory/src/components/TxtViewer.vue";

export default defineComponent({
  name: "InfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    ExportBtn,
    ToggleDocumentView,
    ActsTable,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    FormInputElement,
    CardModalChapter,
    TxtViewer,
    TestImgPreview,
    CaseTableTree: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewCaseTableTree" */
        "@monorepo/inventory/src/views/ERCProtocolsView/components/CaseTableTree.vue"
      ),
    HistoricalNotesTree: () =>
      import(
        /* webpackChunkName: "ERCProtocolsViewCaseTableTree" */
        "@monorepo/inventory/src/views/HistoricalNotesView/components/HistoricalNotesTree.vue"
      ),
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      ModalType,
      isSaveLoading: false,
      key: uuid(),
      modalElements,
      techElements,
      mrpElements,
      historicalNoteElements,
      element: {} as ReturnType<typeof convertApiItemToUi>,
      viewUniqKey,
      openedPanels: [0],
      currentDocumentView: 0,
      isShowDocuments: false,
      openedDocument: "",
      documents: {},
      cardMode: CardMode,
      section: Sections.INVENTORY,
      fields,
      isLoading: false,
      casesList: {},
      isLoadAct: false,
      isShowPdf: false,
      file: null as any,
      noteFiles: [] as any[],
      fileId: null as number | null,
    };
  },
  computed: {
    ...mapGetters("app", ["isExportFileLoading"]),
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("ERCProtocolsView", ["data", "openedId"]),
    isShowTechData(): boolean {
      return isAuthorityExist(this.user, authorities.TECH_INFO);
    },
    isShowActBtn(): boolean {
      return isAuthorityExist(this.user, authorities.ITRA_CREATE);
    },
    isShowAct(): boolean {
      return isAuthorityExist(this.user, authorities.ITRA_LIST);
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.INVENTORY_EXPORT);
    },
    isEditQueuePrestine(): boolean {
      return (this.formValues as { comment: string }).comment?.trim() === (this.changedFormValues as { comment: string }).comment?.trim();
    },
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isNeedAgreeUpon(): boolean {
      return false;
    },
    isNeedApprove(): boolean {
      return true;
    },
    isShowContentTooltip(): unknown {
      return (key: string) => {
        switch (key) {
          case this.fields.INVENTORY_KIND:
          case this.fields.INVENTORY_TYPE:
          case this.fields.INVENTORY_ARCHIVE:
            return true;
          default:
            return false;
        }
      };
    },
    isShowEpSection(this: { element: IERCProtocolsElement }): boolean {
      return !!this.element?.digitalSignatures?.length;
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          default:
            return get(this.element, key);
        }
      };
    },
    modalFieldTitle(): (field: string) => IModalElementType {
      return (field: string) => {
        return this.modalElements.find((item: IModalElementType) => item.value === field) || ({} as IModalElementType);
      };
    },
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          loadCardElement: (value: string) => void;
          $route: Route;
          element: ReturnType<typeof convertApiItemToUi>;
          isShowPdf: boolean;
          fileId: number;
          noteFiles: any[];
        },
        value
      ) {
        if (value) {
          this.isShowPdf = false;
          await this.addQueryOpenedId();
          await this.loadCardElement(value);
        }
        if (this.$route.query?.previewFileId) {
          const queryFile = this.noteFiles?.find((item: any) => item.id?.toString() === this.$route.query?.previewFileId);
          if (queryFile && (queryFile.previewStatus?.code || queryFile.pages?.length)) {
            this.fileId = Number(this.$route.query.previewFileId);
            this.isShowPdf = true;
          } else {
            this.isShowPdf = false;
          }
        }
      },
    },
  },
  methods: {
    ...mapActions("app", ["openNewWindow"]),
    ...mapActions("ERCProtocolsView", [
      "changeOpenedId",
      "formAct",
      "getDocumentList",
      "getInventoryElement",
      "saveComment",
      "getProtocolByGuid",
      "getScreenshot",
      "getTxtFile",
    ]),
    async loadCardElement(value: string) {
      if (value) {
        this.isLoading = true;
        await this.getInventoryElement(value)
          .then(async (data: IApiERCProtocolsElement) => {
            this.getDocumentList(data?.id || "").then((data) => {
              if (data) {
                this.isShowDocuments = true;
              }
            });
            const protocolInfo = await this.getProtocolByGuid(data.guid);
            this.element = convertApiItemToUi(data || {}, protocolInfo);
            this.formValues = { comment: this.element.comment };
            this.changedFormValues = { ...((this.formValues as Record<string, unknown>) || {}) };
          })
          .catch(console.error)
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    refreshCard() {
      this.loadCardElement(this.openedId);
    },
    downloadMrd(id: string) {
      this.openNewWindow(`${getFullPath(QUERY_PATH.GET_FILE_FROM_FILE_STORAGE)}?uuid=${id}`);
    },
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    handleOpenAct(id: string) {
      this.$router.push({
        path: "/inventory/acts",
        query: { id, isOpenModal: "1" },
      });
    },
    async handleFormAct() {
      const params = {
        oikCode: this.element?.oik?.code,
        fundCode: this.element?.fund?.code,
        inventoryId: this.element.id,
      };
      this.isLoadAct = true;
      const savedId = await this.formAct(params).finally(() => {
        this.isLoadAct = false;
      });

      if (savedId) {
        showNotification("Акт успешно сформирован.", NOTIFICATION_STATUS.SUCCESS);
        this.handleOpenAct(savedId);
      } else {
        showNotification("Ошибка формирования акта.");
      }
    },
    clickElementCb(header: { isLink: boolean; value: string }) {
      switch (header.value) {
        case "inventoryFund":
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/fund", {
            number: this.element?.inventoryFund || "",
            id: this.element?.fund?.id || "",
            isOpenModal: "1",
          });
          break;
        case "oikShortName":
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/dictionaries/oik", {
            name: this.element?.oikShortName || "",
            isOpenModal: "1",
          });
          break;
        case "inventoryProtocolHeader":
          (
            this as unknown as { moveByAuthorities: (header: { isLink: boolean }, path: string, query: Record<string, unknown>) => void }
          ).moveByAuthorities(header, "/erc/inventory", {
            id: this.element?.inventoryProtocolId || "",
            isOpenModal: "1",
          });
          break;
        default:
          break;
      }
    },
    closeModal() {
      if (this.type !== ModalType.READ) {
        const isClose = (this as unknown as { checkChangesBeforeClose: () => boolean }).checkChangesBeforeClose();
        if (isClose) {
          return void this.$emit("close");
        }
        return;
      }
      this.$emit("close");
    },
    downloadPdf() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    openCardPdf(id: string) {
      if (id) {
        this.openNewWindow(`${getFullPath(QUERY_PATH.GET_ERC_PROTOCOLS_PDF)}/${id}`);
      }
    },
    openPreview(fileId: number) {
      this.fileId = fileId;
      this.isShowPdf = true;
    },
    getResultSaveData(data: Record<string, string>) {
      return {
        comment: data.comment || "",
      };
    },
    async onSave(data: Record<string, string>) {
      const isNeedSaveComment =
        (this.formValues as { comment: string }).comment?.trim() !== (this.changedFormValues as { comment: string }).comment?.trim();
      if (isNeedSaveComment) {
        const resultData = { ...this.getResultSaveData(data), entityId: this.openedId };

        this.isSaveLoading = true;
        const isSavedComment = isNeedSaveComment ? await this.saveComment(resultData) : true;
        this.isSaveLoading = false;
        if (isSavedComment) {
          this.changedFormValues = this.getResultSaveData(resultData);
          showNotification("Данные успешно сохранены.", NOTIFICATION_STATUS.SUCCESS);
          this.closeModal();
        }
      } else {
        return;
      }
    },
    changeContracts(
      this: {
        $formulate: {
          setValues: (key: string, object: Record<string, string>) => void;
        };
      },
      contracts: Record<string, string>[],
      values: Record<string, string>
    ) {
      (
        this.$formulate as {
          setValues: (key: string, object: Record<string, string>) => void;
        }
      ).setValues("erc-protocols-info-modal", Object.assign(values, { contracts }));
    },
    onFileNameClick({ item }: any) {
      this.file = item;
      this.fileId = item.id;
      this.noteFiles = item.historicalNote?.fileList ?? [];

      this.isShowPdf = true;
    },
  },
  setup(props, context) {
    const { root } = context;
    const formValues = ref({});
    const changedFormValues = ref({});
    const { checkChangesBeforeClose, checkChangesBeforeSave } = useCheckChangesModal(context, formValues, changedFormValues, convertSaveCardObject);
    useModalChangeByKeypress(root, "ERCProtocolsView", Sections.INVENTORY, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "ERCProtocolsView");
    const { resultModalElements, moveByAuthorities } = useFormLinkByAuthoritiesModal(root, modalElements);

    return {
      formValues,
      changedFormValues,
      addQueryOpenedId,
      checkChangesBeforeClose,
      checkChangesBeforeSave,
      resultModalElements,
      moveByAuthorities,
    };
  },
});
